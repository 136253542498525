import type { RichTextField } from "@sitecore-jss/sitecore-jss-nextjs";
import SCRichText from "components/sc/SCRichText";

interface Fields {
    ContentText: RichTextField;
}

export type RichTextProps = {
    params: { [key: string]: string };
    fields: Fields;
};

export const RichText = (props: RichTextProps) => {
    if (!props.fields) return null;
    return <SCRichText richTextField={props.fields?.ContentText} />;
};

export const RichTextBoxed = (props: RichTextProps) => {
    if (!props.fields) return null;
    return (
        <SCRichText
            richTextField={props.fields?.ContentText}
            backgroundColor={"brand.lighterGray"}
            p={{ base: "sp16", lg: "sp32" }}
            borderRadius={"base"}
        />
    );
};
